<template>
  <v-container fluid>
    <v-snackbar color="green" top v-model="snackbarDeleteImagem" :timeout="timeout" content-class="text--center">Imagem
      deletada com sucesso.</v-snackbar>
    <v-snackbar color="error" top v-model="snackbarErroPublicar" :timeout="timeout" content-class="text--center">Erro ao
      publicar denúncia.</v-snackbar>
    <v-snackbar color="error" top v-model="snackbarErroSalvar" :timeout="timeout" content-class="text--center">Erro ao
      salvar denúncia.</v-snackbar>
    <v-snackbar color="green" top v-model="snackbarDelete" :timeout="timeout" content-class="text--center">Denúncia
      deletada com sucesso.</v-snackbar>
    <v-dialog v-model="deleteDialog" width="700">
      <v-card>

        <v-card-title>Vocé tem certeza que deseja DELETAR essa denúncia?</v-card-title>
        <v-card-subtitle class="pb-0">Essa ação não poderá ser desfeita.</v-card-subtitle>
        <v-card-actions class="pb-4" style="justify-content: flex-end;">
          <v-btn @click="deletarDenuncia()" color="error">DELETAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="1200" style="overflow:hidden">
      <v-snackbar color="green" top v-model="snackbarUpdate" :timeout="timeout" content-class="text--center">Denúncia
        atualizada com sucesso.
      </v-snackbar>
      <v-card>
        <v-card-title>ID: {{ dialogItem.id }}</v-card-title>
        <v-row class="pa-0 ma-0">
          <v-col v-if="dialogItem.photo" cols="4">
            <div v-if="dialogItem.photo.length == 1">
              <v-img height="auto" width="300" :src="getSrcImg(dialogItem.photo[0])">
                <div class="btn-cont">
                  <v-btn color="error" @click="deleteImg(dialogItem.photo[0])"
                    style="width:115px;margin-bottom: 6px;"><v-icon>mdi-trash-can</v-icon></v-btn>
                </div>
              </v-img>

            </div>
            <div v-else>
              <v-carousel class="carousel-img" cycle height="300" width="300" hide-delimiters hide-delimiter-background>
                <v-carousel-item v-for="(slide, i) in dialogItem.photo" :key="i" :src="getSrcImg(slide)">
                  <div class="btn-cont">
                    <v-btn color="error" @click="deleteImgCarousel(slide)"
                      style="width:115px;margin-bottom: 6px;"><v-icon>mdi-trash-can</v-icon></v-btn>
                  </div>
                  <!-- <div class="container-btn">
                    <v-btn color="green" class="carousel-btn">
                      <v-icon color="white">mdi-plus-circle</v-icon>
                    </v-btn>
                    <v-btn color="error" class="carousel-btn-2">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </div> -->
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
          <v-col>
            <v-select v-model="dialogItem.categories" :value="dialogItem.categories" outlined color="blue"
              no-data-text="Nenhuma categoria cadastrada" label="Categoria" :item-text="dialogItem.categories"
              :items="tabela.categorias" hide-details />
            <div>
              <v-select class="mt-6" v-model="dialogItem.elements" :value="dialogItem.elements" outlined color="blue"
                no-data-text="Nenhum elemento cadastrado" label="Elemento" :item-text="dialogItem.elements"
                :items="getElementos" hide-details />
            </div>
            <div>
              <v-select chips class="mt-6" v-model="dialogItem.impacts" outlined multiple color="blue" label="Impactos"
                :disabled="!dialogItem.elements" :items="tabela.impactos" hide-details />
            </div>
            <div>
              <v-textarea counter auto-grow class="mt-6" v-model="dialogItem.relato" label="Relato" outlined></v-textarea>
            </div>
            <div>
              <v-file-input style="width:50%" v-model="addFiles" multiple truncate-length="15" chips outlined hide-details
                label="Anexar fotos" :clearable="true" accept="image/*">
                <template v-slot:selection="{ index, text }">
                  <v-chip close @click:close="deleteChip(index)">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </div>
          </v-col>
        </v-row>
        <div>



        </div>
        <div style="height:80px" class="div-save">
          <v-btn color="accent" :disabled="isDialogDiff" @click="addPhoto"> SALVAR </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-snackbar color="green" top v-model="snackbar" :timeout="timeout" content-class="text--center">
      <span class="d-flex justify-center"> Denuncia publicada! </span>
    </v-snackbar>

    <div class="text-h5 mb-8">Denuncias para analisar</div>

    <v-data-table :headers="headers" :items="denunciasAnalisar" :items-per-page="10" show-expand :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" :expanded.sync="expanded" locale="pt" class="elevation-1">
      <template v-slot:[`item.actions`]="{ item }">
        <div style="position: flex; justify-content: center;">

          <v-btn @click="openDialog(item)">Editar</v-btn>
          <v-btn color="accent ml-6" @click="publicar(item)">Publicar</v-btn>
          <v-btn color="error ml-6" @click="openDeleteDialog(item)">Deletar</v-btn>
        </div>
      </template>
      <template v-slot:[[`item.publishedAt`]]="{ item }">
        <span>{{ new Date(item.publishedAt).toLocaleString() }}</span>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="my-2">
            <v-col>

              <div><b>Categoria: </b> {{ item.categories }}</div>
              <div><b>Elemento: </b> {{ item.elements }}</div>
              <div><b>Impactos: </b> {{ item.impacts }}</div>
              <div v-if="item.photo"><b>fotos: </b> {{ item.photo.length }}</div>
              <div v-if="item.relato"><b>Relato: </b> {{ item.relato }}</div>
            </v-col>
            <v-col cols="text-center align-center" class="hidden-sm-and-down">
              <div class="text-center" style="width: 100%" v-if="item.photo">
                <v-img v-if="item.photo.length == 1" height="300px" width="300"
                  :src="`${item.photo[0].formats.thumbnail.url}`">
                </v-img>


                <v-carousel v-else class="carousel-img" cycle height="300" width="300" hide-delimiters
                  hide-delimiter-background>
                  <v-carousel-item v-for="(slide, i) in item.photo" :key="i" :src="getSrcImg(slide)" />
                </v-carousel>
              </div>
            </v-col>

          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-btn style="position: absolute; bottom: 16px" color="accent" @click="$router.go(-1)">voltar</v-btn>
  </v-container>
</template>
<script>
import tabela from '@/mixin.js'
import request from "@/router/requests.js";

export default {
  mixins: [tabela],
  data() {
    return {
      apiUrl: process.env.VUE_APP_STRAPI_URL,
      rules: [v => v.length <= 250 || 'Maximo 250 caracteres'],
      addFiles: [],
      sortBy: "publishedAt",
      dialog: false,
      deleteDialog: false,
      sortDesc: true,
      count: 0,
      expanded: [],
      snackbar: false,
      timeout: 3000,
      dialogItem: {},
      dialogEdit: {},
      categoriaSelecionada: "",
      elementoSelecionado: "",
      impactosSelecionados: [],
      snackbarDelete: false,
      snackbarErroPublicar: false,
      snackbarErroSalvar: false,
      snackbarDeleteImagem: false,
      snackbarUpdate: false,
      dialogDeleteItem: {},
      headers: [
        {
          text: "Codigo da denúncia",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Denunciante",
          align: "start",
          sortable: true,
          value: "users_permissions_user.username",
        },
        {
          text: "Data da publicação",
          align: "start",
          sortable: true,
          value: "publishedAt",
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
        },
        {
          text: "",
          value: "data-table-expand",
        },
      ],

    };
  },
  props: {
    denuncias: Array,
  },
  methods: {
    async deletarDenuncia() {
      const resp = await request('DELETE', `denuncias/${this.dialogDeleteItem.id}`).then((resp) => {
        if (!resp.error) {
          this.snackbarDelete = true;
          this.deleteDialog = false;
          this.denuncias = this.denuncias.filter(obj => obj.id !== this.dialogDeleteItem.id
          )
          this.$emit('update')
        } else {
          this.deleteDialog = false
        }
      })
    },
    openDeleteDialog(item) {
      this.deleteDialog = true
      this.dialogDeleteItem = item
    },
    async deleteImgCarousel(img) {
      let dialogEdited = {}
      dialogEdited.data = { ...this.dialogItem };
      for (let i = 0; i < dialogEdited.data.photo.length; i++) {
        if (dialogEdited.data.photo[i].hash == img.hash) {
          dialogEdited.data.photo.splice(i, 1);
          break;
        }
      }
      dialogEdited.data.impacts = dialogEdited.data.impacts.join(';')
      const resp = await request('PUT', `denuncias/${dialogEdited.data.id}?populate=*`, dialogEdited).then((resp) => {
        if (!resp.error) {
          this.snackbarDeleteImagem = true
        }
        let item = resp.data;
        item.impacts = item.impacts.split(';')
        this.dialogItem = item;

      })

    },
    deleteChip(index) {
      this.addFiles.splice(index, 1);
    },
    async addPhoto() {
      if (this.addFiles.length >= 1) {

        const formData = new FormData();
        let dialogEdited = { ...this.dialogItem }
        dialogEdited.impacts = dialogEdited.impacts.join(";")
        formData.append("data", JSON.stringify(dialogEdited));

        this.addFiles.forEach(element => {
          formData.append("files.photo", element)
        });

        const res = await fetch(
          `https://de-olho-back.up.railway.app/api/denuncias/${this.dialogItem.id}`,
          {
            method: "PUT",
            body: formData,
            headers: {
              ...(localStorage.getItem("token") && {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }),
            },
          }
        ).then(x => {
          this.getUpdatedItem(this.dialogItem.id)

        });
      } else {
        let dialogEdited = {}
        dialogEdited.data = { ...this.dialogItem }
        dialogEdited.data.impacts = dialogEdited.data.impacts.join(';')
        await request("PUT", `denuncias/${dialogEdited.data.id}`, dialogEdited).then((res) => {
          console.log()
          if (res.error) {
            this.snackbarErroSalvar = true
            return
          }
          this.snackbarUpdate = true
          this.getUpdatedItem(dialogEdited.data.id)
          this.dialog = false;
        })
      }
    },
    async getUpdatedItem(id) {
      const resposta = await request("GET", `denuncias/${id}?populate=*`);
      resposta.data.impacts = resposta.data.impacts.split(';')
      this.dialogItem = resposta.data
      this.addFiles = []
      this.$emit('update')
      return

    },
    async deleteImg(photo) {


      let dialogEdited = Object.assign({}, this.dialogItem);
      dialogEdited.photo = null

      dialogEdited.impacts = dialogEdited.impacts.join(';')
      const resp = await request('PUT', `denuncias/${dialogEdited.id}`, dialogEdited).then((resp) => {
        if (!resp.error) {
          this.snackbarDeleteImagem = true
          this.$emit('update')
        }
        let item = resp.data;
        item.impacts = item.impacts.split(';')
        this.dialogItem = item;

      })
    },

    getSrcImg(photo) {

      if (photo.formats.length > 1) {
        return photo.formats.medium.url
      } else {
        return photo.url
      }
    },
    openDialog(denuncia) {

      this.dialogItem = { ...denuncia };
      this.dialogEdit = { ...denuncia }
      this.dialogItem.impacts = this.dialogItem.impacts.split(';')
      this.dialogEdit.impacts = this.dialogEdit.impacts.split(';')
      this.dialog = true;
    },

    async publicar(denuncia) {
      let denunciaAlterada = {}
      denunciaAlterada.data = Object.assign({}, denuncia);
      let denunciaCopiada = denuncia;
      denunciaAlterada.data.publicado = true;
      let resposta = await request(
        "PUT",
        `denuncias/${denuncia.id}`,
        denunciaAlterada
      ).then((x) => {
        if (x.error) {
          this.snackbarErroPublicar = true
          return
        }
        this.snackbar = true;
        denunciaCopiada.publicado = true;
      });
    },
  },
  computed: {
    isDialogDiff() {
      if (this.getElementos.includes(this.dialogItem.elements) && this.dialogItem.impacts.length >= 1) {
        return false //botao ligado
      } else {
        return true
      }

    },
    dialogImpactsArray() {
      if (this.dialogItem.impacts) {
        return this.dialogitem.impacts.split(';')
      }
      return ''
    },
    denunciasAnalisar() {
      return this.denuncias.filter((x) => !x.publicado);
    },
    getElementos() {
      switch (this.dialogItem.categories) {
        case this.tabela.categorias[0]:
          return this.tabela.elementosAreas
        case this.tabela.categorias[1]:
          return this.tabela.elementosRotas
        case this.tabela.categorias[2]:
          return this.tabela.elementosEstruturas
        case this.tabela.categorias[3]:
          return this.tabela.elementosMancha
        case "Outro":
          return [""];
        default:
          return ["erro", "erro"];
      }
    },
  },
};
</script>
<style scoped>
.v-dialog {
  overflow: hidden !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.div-save {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;

}

.carousel-img {
  width: 350px;
}

.container-btn {
  position: relative;
}

.carousel-btn {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 50px;
  width: 25%;
}

.btn-cont {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0px;
  position: absolute;
  width: 100%;
}

.carousel-btn-2 {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;

  width: 25%;
}
</style>